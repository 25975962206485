<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">JETEZ VOTRE ANCRE<br>DANS L'OCEAN D'INTERNET</h1>      
      <p class="maintext">« Surfer sur internet » : plus personne ne dit ça, et pourtant !<br>
Le numérique déferle telle une vague sur le monde d’aujourd’hui, alors montez à bord de LM Créations Numériques et je vous accompagnerai dans votre développement sur internet à l’aide de site web, de production vidéo et de création de contenu. Localisée dans le Sud-Ouest, je souhaite rendre service aux entreprises de la Lomagne, Tarn-et-Garonne, Gers, Landes… et bien évidemment au-delà !

</p>
    </section>
    <section id="anchor" class="homeservices">
      <div class="homeservices__web">
        <img src="../assets/surfer1.svg" alt="Illustration surfeur qui surfe une vague avec un téléphone portable comme planche, et des icones de réseaux sociaux" class="homeservices__img revealleft">
        <div class="homeservices__block revealright">
            <h2 class="homeservices__title">CREATION SITE WEB</h2>
            <p class="homeservices__text">Pour voguer sereinement sur l’océan du web, il est aujourd'hui indispensable d'avoir un site internet. Qu'il soit simplement une vitrine ou un Wordpress, un blog ou encore un site e-commerce Prestashop, votre site sera votre embarcation !
<br>
                <br>
             Je vous propose de prendre la barre et de vous guider dans votre projet de création de site web.</p>   
             <router-link to="Sitesweb"><a class="aboutbutton">EN SAVOIR PLUS</a></router-link>
        </div>  
      </div>
      <div class="homeservices__video">
        <div class="homeservices__block__left revealleft">
            <h2 class="homeservices__title">PRODUCTION VIDEO</h2>
            <p class="homeservices__text">Telles des feux de navigation, les vidéos vous permettent d'être visible dans l'océan d'internet. En prise de vues réelles ou en motion design, elles sont un moyen de communication extrêmement puissant, que ce soit pour présenter votre entreprise, vos produits, pour rendre compte d'un évènement ou faire passer des informations.<br>
<br>
    Je mettrai pour vous ma casquette de capitaine réalisatrice ou de motion-designeur et vous emmènerai au bout de vos envies de vidéo.</p>
    <router-link to="Videos"><a class="aboutbutton">EN SAVOIR PLUS</a></router-link>
        </div>
        <img src="../assets/surfer2.svg" alt="Illustration surfeuse qui surfe une vague avec un téléphone portable comme planche, et des icones de réseaux sociaux"  class="homeservices__img revealright">
      </div>
      <div class="homeservices__content">
        <img src="../assets/surfer3.svg" alt="Illustration surfeuse sur une vague avec un téléphone portable comme planche, et des icones de réseaux sociaux"  class="homeservices__img revealleft">
        <div class="homeservices__block revealright">
            <h2 class="homeservices__title">CREATION DE CONTENU</h2>
            <p class="homeservices__text">Vous souhaitez sortir la grand-voile et booster votre présence en ligne ? Optez pour la création de contenu ! Photos, vidéos, infographies et assistance au mailing, composez votre plan de route en fonction de vos besoins. Destiné pour vos réseaux sociaux ou pour votre site web, la création de contenu est disponible dans une formule à la carte ou sur mesure.<br>
<br>
    Je vous apporterai mes connaissances en digital marketing et en content management pour vous assister dans la montée en puissance de la visibilité de votre entreprise.</p>
    <router-link to="Creationcontenu"><a class="aboutbutton">EN SAVOIR PLUS</a></router-link>
        </div> 
      </div>
    </section>
    <section id="homeref">
    <Logoslideshow>
    </Logoslideshow>
    </section>
    <section id="homewho">
       <div class="homeservices__who">
        <img src="../assets/surfer4.svg" alt="Illustration surfeuse sur une vague avec un téléphone portable comme planche, et des icones de réseaux sociaux"  class="homeservices__img revealleft">
        <div class="homeservices__block revealright">
            <h2 class="homeservices__title">A PROPOS DE MOI</h2>
            <p class="homeservices__text">Surfeuse dans l'océan comme dans les écrans, si vous ne me trouvez pas sur une planche, c'est que je suis en train de m'occuper de vos projets ! Après un parcours atypique, qui m'a menée à obtenir un Master en Audiovisuel option Infographie et un Diplôme de Développeur Web, j'ai réalisé de nombreux projets variés pour des clients satisfaits. J'aurai à cœur de m'occuper du votre !</p>            
                <router-link to="APropos"><div class="aboutbutton">EN SAVOIR PLUS</div></router-link>               
        </div>  
      </div>
    </section>
  </div>
</template>

<script>
import Logoslideshow from '../components/logoslideshow.vue';
import $ from 'jquery';

export default {
  name: 'Home',
  components: {
    Logoslideshow
  },
  mounted() {
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
        /*$(window).click(function() {
          $('.revealleft').removeClass('reveal_visible').removeClass('reveal_pending');
          lTime = performance.now() + 500;
          $('.revealright').removeClass('reveal_visible').removeClass('reveal_pending');
          lTime = performance.now() + 500;
          var top = $(window).scrollTop();
          $(window).scrollTop(top === 0 ? 1 : top-1);
        });*/
  }
}
</script>

<style lang="scss">
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 95%;
	}
  @media (max-width: 325px) {
		font-size: x-small;
    width: 100%;
	}
}

.homeservices {
  background-image: url('../assets/svg-path3.svg');
  background-size: contain; 
  background-position-y: center;
    background-repeat: no-repeat;
  @media screen and (max-width: 980px){
            background-size: cover;
            background-position-y: 300px;
            }
  &__text {
    color: black;
    margin-bottom: 50px;
  }
  &__web {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 80px;
    overflow: hidden;
    @media screen and (max-width: 980px){
            flex-direction: column;
            }
  }
  &__video {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 80px; 
     overflow: hidden;  
    @media screen and (max-width: 980px){
            flex-direction: column-reverse;
            }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 80px;
    overflow: hidden;
    @media screen and (max-width: 980px){
            flex-direction: column;
            }
  }
  &__who {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
    overflow: hidden;
    background-image: url('../assets/wavej4.svg');
    background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
  @media screen and (max-width: 980px){
            background-size: cover;
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 0;
            }
            
  }
  &__block {
    width: 35%;
    margin-left: -10%;
    margin-right: 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 20px;
    @media screen and (max-width: 980px){
            width: 80%;
            margin: auto;
            margin-bottom: 20px;
            }
    &__left {
      margin-left: 3%;
      margin-right: -10%;
      width: 35%;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      @media screen and (max-width: 980px){
              width: 80%;
              margin: auto;
              margin-bottom: 20px;
              }
    }
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01fab;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    font-size: medium;
  }
  &__img {
    width: 50%;
    z-index: 3;
    @media screen and (max-width: 980px){
            width: 98%;
            margin: auto;
            }
  }
}

.aboutbutton {
    border: 2px solid #386ed8;
    width: fit-content;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            }
}

.aboutbuttonrea {
    width: fit-content;
    padding: 17px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
    color: white;
    background: linear-gradient(335deg, rgba(28,9,224,1) -50%, rgba(56,224,31,1) 100%);
     &:hover {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background: linear-gradient(335deg, rgba(28,9,224,1) 0%, rgba(56,224,31,1) 100%);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 980px){
            width: 100%;
            text-align: center;
            }
}

.aboutbuttonline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 580px){
            flex-direction: column;
            }
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
</style>
